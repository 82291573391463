@import "../node_modules/antd/dist/antd.less";
@import (optional) "~@digatex/digatex-ui-lib/dist/@{theme}.less";

body {
	margin: 0;
	font-family: Calibri, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button.stock-level {
	cursor: pointer;
	color: white;
	text-decoration: none !important;
	border: 1px solid @primary-color;
	border-radius: 0.25em;
	padding: 0.1em 0.2em;
	word-break: keep-all;
	min-width: 50px;
	background-color: @primary-color;
}

header.ant-layout-header {
	background: @header-background;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	z-index: 999;
}

header .ant-spin-blur::after {
	opacity: 0;
}

.best-option {
	background-color: #ccffcc;
}

.submit-button {
	background-color: @primary-color;
	color: #ffffff;
}

.full-search {
	background-color: #fddbff66;
	color: #000000;
}

.partial-search {
	background-color: #dbffdb66;
	color: #000000;
}

.gt-search {
	background-color: #ffdbdd66;
}
.lt-search {
	background-color: #fff8db66;
}
.eq-search {
	background-color: #dbe4ff66;
}

.beautiful-link {
	color: @primary-color;
	cursor: pointer;
}

.card-settings {
	border-color: @primary-color;
	color: @primary-color;
}

.site-layout-background {
	background: #fff;
}

#logo {
	height: 48px;
}

#home-button > svg {
	position: relative;
	top: calc(50% - 16px);
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.main-text-dashboard {
	width: 350px;
	height: fit-content;
	margin-top: 65px;
	font-size: 25px;
	text-align: center;
}

.main-text-dashboard-1 {
	width: 350px;
	height: 200px;
	margin-top: 60px;
	margin-left: 55px;
	font-size: 25px;
	text-align: center;
}

.sec-text-dashboard {
	width: 350px;
	height: 200px;
	margin-top: 80px;
	margin-left: -85px;
	font-size: 20px;
	text-align: center;
}

#digatex-logo {
	height: 48px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
	left: 0;
	padding: 0;
	border: 0;
}

.ant-input-search-button {
	height: 32px;
	margin-top: 0;
	margin-left: 0;
}

.App {
	text-align: center;
}

.tooltip-inner {
	max-width: 370px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 4px;
}

table.Items tbody tr td {
	line-height: 2.2em;
	border-right-width: 1px;
	border-color: white;
	cursor: default;
}

table.Items thead tr th {
	line-height: 2.2em;
	text-align: center;
	background-color: #cacacc;
	border-right-width: 1px;
	border-color: white;
	vertical-align: middle;
}
a {
	cursor: pointer;
}

.Item-material {
	cursor: pointer;
	word-break: break-word;
	white-space: normal;
}

.Item-part-number {
	text-align: center;
	word-break: break-all;
}

.stock-level button {
	cursor: pointer;
	color: white;
	text-decoration: none !important;
	border: 1px solid #0288d1;
	border-radius: 0.25em;
	padding: 0.1em 0.2em;
	word-break: keep-all;
	min-width: 50px;
	background-color: #0288d1;
}

.Attr {
	border: 1px solid #959595;
	border-radius: 0.25em;
	padding: 0.1em 0.3em;
	margin: 0.1em 0.3em 0.1em 0;
	line-height: 1.5em;
	cursor: default;
	display: inline-block;
}

.Attr-matched {
	cursor: pointer;
}

.Attr-exact {
	background-color: #ccffcc;
}

.Attr-positive {
	background-color: #ffffaa;
}

.Attr-negative {
	background-color: #ffaeae;
}

.App-query input {
	margin-top: 15px;
}
.App-query button {
	margin-top: 15px;
}

.App-query img {
	margin-top: 21px;
}

.App-query span {
	font-family: "Courier New";
	font-size: 1.5em;
	color: white;
}

.App-info {
	font-color: #333f4f;
	margin-top: 10px;
	margin-bottom: 10px;
}

.App-info span {
	padding: 20px;
}

.App-root {
	max-width: 1200px;
	padding: 10px;
}

.tooltip.top .tooltip-inner {
	background-color: #333f4f;
}

#requestText {
	width: 100%;
	height: 330px;
	margin-top: 10px;
}

.Contact-form textarea.form-control {
	height: 180px;
}

.form-group.required .control-label:after {
	content: " *";
	color: red;
}
.center {
	margin-left: 14%;
}
.lbl {
	margin-left: 18px;
	margin-right: 10px;
}
.MuiMenu-paper {
	max-height: calc(100% - 96px);
	margin-top: 65px;
	-webkit-overflow-scrolling: touch;
}
.col-md-1 {
	min-height: 1px;
	text-align: center;
	padding-right: 0;
	padding-left: 0;
}
.link {
	margin-left: 40%;
}
.hub-name {
	font-size: 15px;
	text-align: center;
	position: relative;
}

.heading {
	position: static;
	margin: 0;
	padding-top: 6px;
	padding-bottom: 6px;
	color: black;
	font-size: 12px;
	max-height: 38px;
}

.hub-name {
	font-size: 15px;
	text-align: center;
	position: relative;
}

.supplier-copy {
	display: inline-block;
	background-color: #e8e6ff;
	border: 1px solid #1e00ff;
	color: #1e00ff;
	padding: 0.1em 0.3em;
	margin: 0.1em 0.3em 0.1em 0;
	line-height: 1.5em;
}

.url-link {
	color: #004987;
	cursor: pointer;
}

.button {
	background-color: #007fc5;
	color: white;
	cursor: pointer;
}

.site-tree-search-value {
	color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
	color: #d84a1b;
}

.float-right {
	float: right;
	margin-bottom: 10px;
}

.approved {
	background-color: rgba(162, 199, 226, 0.5);
}

.custom-filter-dropdown {
	padding: 8px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.stat-price span {
	font-size: 12px;
}

.stat-negative-value span {
	color: #95190c;
}

.stat-positive-value span {
	color: #00783f;
}

.big-white-panel {
	margin: 10px;
	background-color: white;
	min-height: 90vh;
}

.nested-table-commonality thead th {
	background-color: rgba(0, 120, 63, 0.2);
}

.landing-links {
	margin: 5px;
}

.disabled-product {
	background-color: #80808033;
}

@theme: kbr;